import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("G-KBGJW8V6L1");
    // }
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      let screen = location.pathname.split('/')[0]


    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search,title: screen });
    }
  }, [initialized, location]);
};



export default usePageTracking;