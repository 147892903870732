/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";
import ReactGA from 'react-ga4';

// react-router components
import { Routes, Route, useLocation } from "react-router-dom";


//Custom Components
import SiteHeader from "components/SiteHeader/SiteHeader";
import SiteFooter from "components/SiteFooter/SiteFooter";

//Context
import { AuthProvider } from "utils/AuthContext";
import { CartProvider } from "utils/CartContext";
import { StoreProvider } from "utils/StoreContext";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 React themes
import theme from "assets/theme";

//Pages
import HomePage from "./pages/HomePage/HomePage";
import ProductPage from "./pages/ProductPage/ProductPage";
import CategoryPage from "./pages/CategoryPage/CategoryPage";
import OrderPage from "./pages/OrderPage/OrderPage";
import RecipePage from "./pages/RecipePage/RecipePage";
import AuthPage from "./pages/AuthPage/AuthPage";
import CartPage from "./pages/CartPage/CartPage";
import HealthPage from "./pages/HealthPage/HealthPage";
import SearchResultPage from "pages/SearchResultPage/SearchResultPage";

//Utils
import usePageTracking from "utils/usePageTracking";

// Material Kit 2 React routes
import routes from "routes";

export default function App() {
  const { pathname } = useLocation();

  usePageTracking();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });


  return (
    <div className="App">

      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <CartProvider>
          <StoreProvider>
            <SiteHeader/>


            <div>
              <Routes>
                {getRoutes(routes)}
                <Route path="/auth" element={<AuthPage />} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/search_results" element={<SearchResultPage />} />
                <Route exact path="/_healthz" element={<HealthPage />} />
                <Route path="/orders" element={<OrderPage />} />
                <Route path="/category/:id" element={<CategoryPage />} />
                <Route path="/product/:id" element={<ProductPage />} />
                <Route path="/recipe/:id" element={<RecipePage />} />
                <Route path="/" element={<HomePage />} />
              </Routes>
            </div>
            </StoreProvider>
          </CartProvider>
        </ThemeProvider>
      </AuthProvider>
      <SiteFooter/>

    </div>
  );
}
