import React from "react";

// core components
import Grid from '@mui/material/Grid';


//specific components
import ProductCard from "components/ProductCard/ProductCard.js"


export default function ProductList(props) {

  const products = props.products;

  if (products && products.length > 0) {
    return (

      <div>
        <Grid container display="flex" justifyContent="center" alignItems="stretch" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {
            products.map((product, index) => {
              return (
                <Grid item   key={product.id}>
                  <ProductCard product={product} />
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    )
  } else {
    return (<div></div>)
  }







}
