import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

import { StoreContext } from 'utils/StoreContext';

//Sections
import ProductList from "../Components/Sections/ProductList.js";

import API from "utils/API";


// core components
import Container from "@mui/material/Container";
import Pagination from '@mui/material/Pagination';

//styles
// Migration import styles from "assets/jss/material-kit-react/views/homepage.js";
import { devUrl } from "utils/API.js";
import { Grid, Typography } from "@mui/material";

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';


export default function SearchResultPage(props) {
  const location = useLocation();
  const storeContext = useContext(StoreContext);

  const searchText = location?.state?.searchText;

  const [page, setPage] = useState(1);
  const [searchResults, getSearchResults] = useState({});
  const [total_results, setTotalResults] = useState(0);
  const [total_pages, setTotalPages] = useState(0);
  const [is_searching, setSearching] = useState(false);


  useEffect(() => {
    if (page == 1) {
      search();
    } else {
      setPage(1);
    }

  }, [searchText]);

  useEffect(() => {
    search();
  }, [page,storeContext]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const search = () => {
    setSearching(true);
    let params = 'query=' + searchText;
    let page_size = 12;
    params += "&page_size=" + page_size;
    if (page > 1) {
      params += "&page_num=" + page;
    }

    if (storeContext.store.id){
      params += '&places='+storeContext.store.id;
    }

    let result = devUrl.get("/search?" + params).then((response) => {
      const result = response.data;
      console.log(response.data)
      setTotalResults(result.total_size);
      if ((result.total_size % page_size) > 0) {
        setTotalPages(Math.floor(result.total_size / page_size) + 1)
      } else {
        setTotalPages(Math.floor(result.total_size / page_size))
      }
      setSearching(false);
      getSearchResults(result.results);
      ReactGA.event( "search",{ search_term:searchText});
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (is_searching == false) {
    return (
      <div>
        <Container maxWidth="lg" style={{ marginTop: "8rem" }}>

          {searchResults && searchResults.length > 0 ? (
            <div>
              <Grid container rowSpacing={4} justifyContent="center">
                <Grid item xs={12}>
                  <Typography variant="h4">Recherche produits - {total_results} resultats</Typography>
                </Grid>
                <Grid item display="flex">
                  <ProductList products={searchResults} />
                </Grid>
                <Grid item xs={12} display="flex" >
                  <Pagination count={total_pages} page={page} onChange={handlePageChange}  sx={{"margin":"auto"}} size="large" color="info" />
                </Grid>
              </Grid>
            </div>
          ) : <Grid container rowSpacing={4}>
            <Grid item xs={12} justifyContent="center" sx={{ "display": "flex" }}>
              <Typography variant="h4">Aucun résultat - Veuillez modifier votre recherche</Typography>
            </Grid>
          </Grid>}


        </Container>
      </div >
    );
  } else {
    return (
      <div></div>
    )
  }
}
