import React from "react";

import { NavLink } from "react-router-dom";
// material-ui components
import { makeStyles } from "@mui/styles";

// core components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent'


import { cardTitle } from "assets/jss/material-kit-react.js";

import IconButton from "@mui/material/IconButton";

import TimerIcon from "@mui/icons-material/Timer";
import DinningIcon from "@mui/icons-material/LocalDining";
import FavoriteIcon from "@mui/icons-material/Favorite";

const styles = {
  cardTitle,
  prodcard: {
    textAlign: "center",
    maxWidth: "17rem",
    mminWidth: "15rem",
  },
  prodCardWithPicture: {
    height: "25rem",
  },
  prodFooter: {
    fontWeight: 700,
  },
  cardActions: {
    marginLeft: "auto",
  },
  cardIcons:{
    paddingRight:"5px"
  }
};

const useStyles = makeStyles(styles);

export default function Cards(props) {
  const classes = useStyles();
  const recipe = props.recipe;
  return (
    <div>
      <NavLink
        to={{
          pathname: "/recipe/" + recipe.id,
          productProps: recipe,
        }}
        className={classes.cardTitle}
      >
        <Card
          className={
            (classes.prodcard,
            props.hidePicture != true ? classes.prodCardWithPicture : "")
          }
        >
          {props.hidePicture != true && (
            <img
              style={{
                display: "block",
                width: "100%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className={classes.imgCardTop}
              src={recipe.picture}
              alt="Card-img-cap"
            />
          )}

          <CardContent>
            <div
              style={{
                maxHeight: "4rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textOverflow: "ellipsis",
                display: "webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              <h4 className={classes.cardTitle}>{recipe.name}</h4>
            </div>
          </CardContent>

           
            <CardActions disableSpacing className={classes.cardActions}>
            <div className={classes.cardIcons}>
              <TimerIcon />
              <div>{recipe.totalTime}</div>
            </div>
            <div className={classes.cardIcons}>
              <DinningIcon />
              <div>{recipe.level}</div>
            </div>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
            </CardActions>

        </Card>
      </NavLink>
    </div>
  );
}
