import React from "react";

import { NavLink } from "react-router-dom";
// material-ui components
import { makeStyles } from "@mui/styles";
// core components
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'
import CardMedia from "@mui/material/CardMedia";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Typography } from "@mui/material";

import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = {
  cardTitle,
  catcard: {
    height: "15rem",
    textAlign: "center",
  },
  textMuted: {
    color: "#6c757d",
  },
};

const useStyles = makeStyles(styles);

export default function Cards(props) {
  const classes = useStyles();
  return (
    <div>
      <NavLink
        to={{
          pathname: "/category/" + props.id,
        }}
        className={classes.cardTitle}
      >

      <Card variant="outlined" sx={{maxWidth:200, height:"17rem"}}>
      <CardMedia
        component="img"
        image={props.src}
        alt= {props.title}
      />
      <CardContent>
      <Typography align="center" variant="h6" component="div">
            {props.title}
            </Typography>
          </CardContent>
      </Card>

        {/* <Card className={classes.catcard}>
          <img
            style={{
              width: "8rem",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className={classes.imgCardTop}
            src={props.src}
            alt="Card-img-cap"
          />
          <CardContent>
            <h4 className={classes.cardTitle}>{props.title}</h4>
          </CardContent>
        </Card> */}
      </NavLink>
    </div>
  );
}
