import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCF86mocP80iw4hEawA5sAc0Z1ATps-2_A",
  authDomain: "sca-eco.firebaseapp.com",
  };
  
  firebase.initializeApp(firebaseConfig);
  //export const auth = firebase.auth();

  export default firebase
