import React, { createContext, useState, useEffect,useContext } from "react";
//Context
import { AuthContext } from "utils/AuthContext";

import API from "utils/API";

export const CartContext = createContext({
  cart: null,
  itemCount: 0,
  refreshCart: () => {}
});


export const CartConsumer = ({ children }) => {
  return <CartContext.Consumer>{children}</CartContext.Consumer>;
};

export const CartProvider = ({ children }) => {
  const [cart, setCartItems] = useState({});
  const authContext = useContext(AuthContext);
  const userUID = authContext.user.uid;


  const itemCount= () => {
    if (cart.cartItems){
      console.log(cart.cartItems.length)
      return cart.cartItems.length
    } else {
      return 0
    }
    
  }

  useEffect(() => {
    getAllItems();
  }, [userUID]);

  const getAllItems = () => {
    //Getting Cart only if user is identified
    if (userUID){
      let result = API.get("/cart/" + userUID).then((response) => {
        const allItems = response.data;
        console.log("Items for user:"+userUID);
        console.log("Getting cart items")
        console.log(allItems)
        setCartItems(allItems);
        
      });
    }
    
  };

  const refreshCart = () => {
    console.log('Refreshing Cart')
    getAllItems();
    return cart;
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        itemCount,
        refreshCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

