import React from "react";

// core components
import Grid from '@mui/material/Grid';

//specific components
import ProductCategoryCard from "components/ProductCategoryCard/ProductCategoryCard.js"
import { Typography } from "@mui/material";


export default function ProductCategory(props) {


  let categories;
  const isSubCategory = props.isSubCategory;
  if (isSubCategory) {
    categories = props.categories.categories;
  } else {
    categories = props.categories;

  }


  if (categories && categories.length > 0) {
    return (
      <div>
        <Grid container  spacing={3}   direction="row" justifyContent="center" alignItems="stretch">
          {
            categories.map((category, index) => {
              return (
                <Grid item xs={12} sm={4} md={4} lg={2} key={category.id}>
                  <ProductCategoryCard id={category.id} title={category.name} src={category.picture} />
                </Grid>
              )
            })
          }
        </Grid>
      </div>

    )
  } else {
    return (
      <Typography variant="h2">Pas de rubriques</Typography>
      )
  }

}
