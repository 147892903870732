import React, { useState, useEffect } from "react";

//Migration import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @mui/material components
import { makeStyles } from "@mui/styles";

import Grid from '@mui/material/Grid';

import MKBox from "components/MKBox";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";

import API from "utils/API";
import { devUrl } from "utils/API.js";

import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Icons
// @mui/icons
import Storefront from "@mui/icons-material/Storefront";
import Restaurant from "@mui/icons-material/Restaurant";

import bgImage from "assets/images/header_vegetables.jpg";

//styles
// Migration import styles from "assets/jss/material-kit-react/views/homepage.js";

//Sections
import ProductCategory from "pages/Components/Sections/HomeProductCategories";
// import ProductCategory from "../Components/Sections/HomeProductCategories.js";
// import RecipeList from "pages/Components/Sections/RecipeList.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


export default function HomePage(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [categories, getCategories] = useState({});
  const [recipeCategories, getRecipeCategories] = useState({});
  useEffect(() => {
    getAllCategories();
    getAllRecipeCategories();
  }, []);

  const getAllCategories = () => {
    let result = devUrl.get("/categories").then((response) => {
      const allCategories = response.data.categories;
      console.log("Retrieving all categories");
      console.log(allCategories);
      getCategories(allCategories);
    });
  };

  const getAllRecipeCategories = () => {
    let result = API.get("/recipecategories").then((response) => {
      const allRecipeCategories = response.data;
      getRecipeCategories(allRecipeCategories);
    });
  };

  return (
    <div>
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >

        </MKBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>

              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="Univers"
              >
                <Tab icon={<Storefront />} label="Nos rayons" {...a11yProps(0)} />
                <Tab icon={<Restaurant />} label="Nos Recettes" {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                <ProductCategory categories={categories} />
              </TabPanel>
              <TabPanel value={value} index={1}>
                Recipes
              </TabPanel>


            </Grid>
          </Grid>

        </Card>

      </MKBox>

      <div ></div>

    </div>
  );
}
