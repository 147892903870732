import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

import API from "utils/API";

// core components
import Container from "@mui/material/Container";

import Grid from '@mui/material/Grid';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Paper from "@mui/material/Paper";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

// @mui/material components
import { makeStyles } from "@mui/styles";

// @mui/icons
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import EventIcon from "@mui/icons-material/Event";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PaymentIcon from "@mui/icons-material/Payment";

//Context
import { AuthContext } from "utils/AuthContext";

//styles
//import styles from "assets/jss/material-kit-react/views/components.js";
import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = {
  cardTitle,
  prodcard: {
    textAlign: "center",
    maxWidth: "17rem",
    mminWidth: "15rem",
  },
  prodCardWithPicture: {
    height: "20rem",
  },
  prodFooter: {
    fontWeight: 700,
  },
  cardActions: {
    marginLeft: "auto",
  },
};

const useStyles = makeStyles(styles);

export default function OrderPage(props) {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const userUID = authContext.user.uid;

  const [orders, getOrders] = useState({});
  useEffect(() => {
    getAllOrders();
  }, []);

  const getAllOrders = () => {
    let result = API.get("/orders/" + userUID).then((response) => {
      const allOrders = response.data;
      console.log("Return from Order service");
      console.log(allOrders);
      getOrders(allOrders);
    });
  };

  if (orders && orders.length > 0) {
    return (
      <Container maxWidth="lg" style={{ marginTop: "8rem" }}>
        <Stack spacing={2}>
          <Typography variant="h3">
            Commandes passées
          </Typography>
          {orders.map((order, index) => {
            return (
              <div key={order.ID}>
                <Grid item xs={12} >
                  <Grid container>
                    <Grid item xs={12} >
                      <Typography variant="h5">
                        Commande n° {order.ID}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Grid container >
                        <Grid item xs={12} sm={4}>
                          <List dense={true}>
                            <ListItem>
                              <ListItemIcon>
                                <EventIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={order.CreationDT}
                                secondary="Date de création"
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <AccountTreeIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={order.Status}
                                secondary="Statut"
                              />
                            </ListItem>
                            <ListItem>
                              <ListItemIcon>
                                <PaymentIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  (order.TotalPrice.units
                                    ? order.TotalPrice.units
                                    : 0) +
                                  "," +
                                  parseInt(order.TotalPrice.nanos / 10000000) +
                                  " " +
                                  order.TotalPrice.currency_code
                                }
                                secondary="Total"
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                          <Grid container rowSpacing={2}>
                            {order.Lines.map((line, index) => {
                              return (
                                <Grid item xs={12} key={(order.ID) + "" + (line.Product.id)}>
                                  <Card >
                                    <div >
                                      <CardContent>
                                        <Grid container alignItems="center">
                                          <Grid item sm={3} >
                                            <img
                                              style={{
                                                width: "6rem",
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                              }}
                                              className={classes.imgCardTop}
                                              src={line.Product.picture}
                                              alt="Card-img-cap"
                                            />
                                          </Grid>
                                          <Grid item sm={9}>
                                            <Typography variant="h6">
                                              {line.Product.name}
                                            </Typography>
                                            <Typography variant="subtitle2">Quantitée: {line.Quantity}</Typography>
                                            <Typography variant="subtitle2">
                                              Prix:{" "}
                                              {(line.TotalPrice.units
                                                ? line.TotalPrice.units
                                                : 0) +
                                                "," +
                                                parseInt(
                                                  line.TotalPrice.nanos / 10000000
                                                ) +
                                                " " +
                                                line.TotalPrice.currency_code}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    </div>
                                  </Card>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
              </div>

            );
          })}
        </Stack>
      </Container>
    );
  } else {
    return (
      <Container maxWidth="lg" style={{ marginTop: "8rem" }}>
        <Paper elevation={3}>
          <MKBox padding={10}>
            <MKTypography variant="h3" my={1}>
              Pas de commandes
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Vous n'avez pas encore de commande!
            </MKTypography>
          </MKBox>
        </Paper>
      </Container>
    );
  }
}
