import { useContext, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import CartIcon from '@mui/icons-material/ShoppingCart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import LogoutIcon from '@mui/icons-material/Logout';
import LogintIcon from '@mui/icons-material/Login';
import OrderIcon from '@mui/icons-material/ViewList';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import CategoryIcon from '@mui/icons-material/ShoppingBasket';
import TagIcon from '@mui/icons-material/Sell';
import List from '@mui/material/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';

import { devUrl } from 'utils/API';


import { Link as RouterLink, useNavigate } from "react-router-dom";

//Context
import { AuthConsumer, AuthContext } from "utils/AuthContext";
import { CartConsumer } from "utils/CartContext";
import { StoreContext } from 'utils/StoreContext';
import { TextField, FormControl, Divider } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';



const drawerWidth = 240;

export default function SiteHeader() {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [categories, getCategories] = useState({});
  const [stores, getStores] = useState({});

  useEffect(() => {
    getAllCategories();
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const storeContext = useContext(StoreContext)

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const logout = (e) => {
    authContext.logout();
    handleMenuClose()
  };

  const getAllCategories = () => {
    let result = devUrl.get("/categories").then((response) => {
      const allCategories = response.data.categories;
      getCategories(allCategories);
    });
  };

  const [searchTerm, updateSearchTerm] = useState("");

  const updateSearch = (event) => {
    let value = event.target.value;
    updateSearchTerm(value);
  }


  const search = () => {
    navigate("/search_results", { state: { searchText: searchTerm } });
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [deliveryMode, setDeliveryMode] = useState('next-day-delivery');

  const handleDeliveryChange = (event, deliveryMode) => {
    console.log(deliveryMode);
    storeContext.setDeliveryMode(deliveryMode);
    if (deliveryMode == "pickup-in-store") {
      getAllStores();
      setStoreMenuOpen(true);
    } else {
      let tmpStore = {id:""}
      storeContext.setStore(tmpStore);
       setStore(tmpStore);
    }

  };

  const [openStoreMenu, setStoreMenuOpen] = useState(false);
  const [store, setStore] = useState({ 'id': "" });

  const handleStoreChange = (event) => {
    let tmpStore = stores.find(item => item.id === event.target.value);
    storeContext.setStore(tmpStore);
    setStore(tmpStore);
  };

  const handleClickStoreMenuOpen = () => {
    setStoreMenuOpen(true);
  };

  const handleStoreMenuClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setStoreMenuOpen(false);
    }
  };

  const getAllStores = () => {
    let result = devUrl.get("/places").then((response) => {
      const allStores = response.data.places;
      getStores(allStores);
    });
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <AuthConsumer>
        {(authContext) =>
          authContext.authenticated &&
            authContext.user.isAnonymous == false ? (
            <div>
              <MenuItem onClick={handleMenuClose} component={RouterLink}
                to={{
                  pathname: "/orders",
                }}>
                <ListItemIcon>
                  <OrderIcon fontSize="small" />
                </ListItemIcon>
                Mes commandes</MenuItem>
              <Divider />
              <MenuItem onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Deconnection</MenuItem>
            </div>
          ) : (
            <MenuItem onClick={handleMenuClose} component={RouterLink}
              to={{
                pathname: "/auth",
              }}>
              <ListItemIcon>
                <LogintIcon fontSize="small" />
              </ListItemIcon>
              Log in</MenuItem>
          )
        }
      </AuthConsumer>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >

      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <RouterLink to="/">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              Demo components for retail
            </Typography>
          </RouterLink>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <TextField
              id="outlined-adornment-amount"
              value={searchTerm}
              onChange={updateSearch}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  search()
                }
              }}
              sx={{ maxWidth: "45rem", marginLeft: "20px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              label="Rechercher un produit"
              variant="outlined"
            />
          </FormControl>
          <ToggleButtonGroup
            color="info"
            value={storeContext.deliveryMode}
            onChange={handleDeliveryChange}
            exclusive
            aria-label="DeliveryMode"
          >
            <ToggleButton value="next-day-delivery">Livraison</ToggleButton>
            {store.id ? (
              <ToggleButton value="pickup-in-store">{store.name}</ToggleButton>
            ) : (
              <ToggleButton value="pickup-in-store">Drive</ToggleButton>
            )}

          </ToggleButtonGroup>


          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            <IconButton size="large" aria-label="Show cart content" color="inherit" LinkComponent={RouterLink}>
              <CartConsumer>
                {(cartValue) => (
                  <Badge badgeContent={cartValue.itemCount()} color="info" component={RouterLink}
                    to={{
                      pathname: "/cart",
                    }}>
                    <CartIcon color="info" />
                  </Badge>
                )}
              </CartConsumer>
            </IconButton>

            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>



          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <CategoryIcon />
            </ListItemIcon>
            <Typography variant='h6'>Nos catégories</Typography>
          </ListItemButton>
        </ListItem>

        <Divider />
        <List>
          {
            categories && categories.length > 0 && (
              categories.map((category, index) => (
                <ListItem key={category.id} disablePadding >
                  <ListItemButton component={RouterLink} to={{
                    pathname: "/category/" + category.id,
                  }}>
                    <ListItemIcon>
                      <TagIcon />
                    </ListItemIcon>
                    <Typography variant='subtitle2'>{category.name}</Typography>
                  </ListItemButton>
                </ListItem>
              )

              )
            )}
        </List>
      </Drawer>
      <Dialog disableEscapeKeyDown open={openStoreMenu} onClose={handleStoreMenuClose}>
        <DialogTitle>Store locator</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <FormControl sx={{ m: 4, minWidth: 300 }}>
              <InputLabel id="demo-dialog-select-label">Please select a store</InputLabel>
              <Select sx={{ padding: "0.75rem" }}
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={store.id}
                onChange={handleStoreChange}
                input={<OutlinedInput label="Select a store" />}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>

                {stores && stores.length > 0 ? (

                  stores.map((store, index) => {
                    return (
                      <MenuItem value={store.id} key={store.id}>{store.name}</MenuItem>
                    )
                  })
                ) : (
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStoreMenuClose}>Cancel</Button>
          <Button onClick={handleStoreMenuClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>

  );
}

