// utils/API.js

import axios from "axios";

export default axios.create({
  baseURL: "https://shop.sca-demos.com/api/",
  responseType: "json"
});

export var devUrl = axios.create({
  baseURL: "https://shop.sca-demos.com/api_new/",
  responseType: "json"
});