import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import firebase from "utils/Firebase";

export const AuthContext = createContext({
  auth: null,
  provider: null,
  authenticated: false,
  token: null,
  user: firebase.User | null,
  logout: () => {},
  isSignUp: false,
  setSignUp: () => {},
  signInWithEmailAndPasswordHandler : () => {},
  signInWithGoogle : () => {},
});

export const AuthConsumer = ({ children }) => {
  return <AuthContext.Consumer>{children}</AuthContext.Consumer>;
};

export const AuthProvider = ({ children }) => {
  const auth = firebase.auth();
  const [user, setUser] = useState("");
  const [isSignUp, setSignUp] = useState(false);
  const provider = new firebase.auth.GoogleAuthProvider();
  let navigate = useNavigate();

  const signInWithGoogle = () => {
    auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
      })
      .catch((error) => {
        console.error("Error signing in with Google", error);
        return error
        //setError("Error signing in with Google");
        
      });
  };
  const signInWithEmailAndPasswordHandler = (email, password) => {
    //event.preventDefault();
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      console.error("Error signing in with password and email", error);
      return error;
      
    });
  };

  const createAnonymousSession = () => {
    auth
      .signInAnonymously()
      .then(() => {
        console.log("Session created");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("Error setting session:" + errorMessage);
      });
    
      
    

  };

  const logout = () => {
    auth
      .signOut()
      .then(() => {
        setUser("");
      })
      .catch((error) => {
        console.log("Error logging out:" + error);
      });
  };

  const signup = (val) => {
    setSignUp(val);
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      console.log('state changed')
      if (user){ 
        setUser(user);
      } else {
        createAnonymousSession()
      }
      navigate("/");
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        auth,
        provider,
        user,
        authenticated: user !== null,
        setUser,
        logout,
        isSignUp,
        signup,
        signInWithEmailAndPasswordHandler ,
  signInWithGoogle ,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
