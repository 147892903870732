import React, { useContext,useState , useEffect} from "react";

import { NavLink } from "react-router-dom";
// material-ui components
import { makeStyles } from "@mui/styles";

import API from "utils/API";

// core components
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from "@mui/material/CardActionArea";

import { Typography, Box } from "@mui/material";

import { cardTitle } from "assets/jss/material-kit-react.js";

import IconButton from "@mui/material/IconButton";

import FavoriteIcon from "@mui/icons-material/Favorite";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

//Context
import { AuthContext } from "utils/AuthContext";
import { CartContext } from "utils/CartContext";

import ReactGA from "react-ga4";

const styles = {
  cardTitle,
  prodcard: {
    textAlign: "center",
    maxWidth: "15rem",
    mminWidth: "15rem",
  },
  prodCardWithPicture: {
    height: "25rem"
  },
  prodFooter: {
    fontWeight: 700,
  },
  cardActions: {
    marginLeft: "auto",
  },
};

const useStyles = makeStyles(styles);

export default function Cards(props) {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const userUID = authContext.user.uid;

  const cartContext = useContext(CartContext);

  const [price, setPrice] = useState();




  const product = props.product;
  const price_data = product.price_usd;
  useEffect(() => {
    let price= parseFloat(product.price_usd.units + "." + parseInt(product.price_usd.nanos / 10000000));
    setPrice(price);
  }, [product]);



  const addToCart = (product) => {
    let productId = product.id
    console.log("Product Added to cart=>" + productId);
    let result = API.get("/addToCart/" + userUID + "/" + productId).then((response) => {
      const resp = response.data;
      cartContext.refreshCart()
      ReactGA.event( "add_to_cart",{ currency:'USD', value:price,items:[{item_id:product.id,item_name:product.name}]});
    });
  }

  return (
    <div>

      <Card sx={{ minWidth: '15rem', maxWidth: "15rem", display: 'flex', flexDirection: 'column', height: "25rem", justifyContent: "space-between" }} >
        <CardActionArea component={NavLink} to={{
          pathname: "/product/" + product.id,
          productProps: product
        }} sx={{ height: "100%", display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
          <CardMedia sx={{ width: '8rem', margin: "auto" }}
            component="img"
            alt={product.name}
            image={product.picture}
          />
          <CardContent >
            <Typography variant="subtitle2" color="text.secondary">
              {product.name}
            </Typography>
          </CardContent>
        </CardActionArea>


        <CardActions sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" sx={{ marginLeft: "1rem" }}>
            €{price}
          </Typography>
          <Box>
            <IconButton aria-label="add to favorites" >
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share" onClick={() => {
              addToCart(product)
            }}>
              <AddShoppingCartIcon />
            </IconButton>
          </Box>
        </CardActions>
      </Card>



    </div>
  );
}
