import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

export default function SiteFooter() {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box mt={"40px"}>

                </Box>
            </Grid>
        </Grid>
    )

}