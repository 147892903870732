import React, { createContext, useState, useEffect,useContext } from "react";


import API from "utils/API";

export const StoreContext = createContext({
  store: "",
  deliveryMode:""
  
});


export const StoreConsumer = ({ children }) => {
  return <StoreContext.Consumer>{children}</StoreContext.Consumer>;
};

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState({ 'id': "" });
  const [deliveryMode,setDeliveryMode] = useState("next-day-delivery");




  return (
    <StoreContext.Provider
      value={{
        store,
        deliveryMode,
        setStore,
        setDeliveryMode
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};


