import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga4";

import API from "utils/API";

// core components
import Container from "@mui/material/Container";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import CountUp from "react-countup";

import Grid from '@mui/material/Grid';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from "@mui/material/Paper";

import Link from '@mui/material/Link';

import Button from "@mui/material/Button";

import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Otis Kit PRO components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


// @mui/icons
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";

//styles
// Migration import styles from "assets/jss/material-kit-react/views/components.js";
import { devUrl } from "utils/API";

import { AuthContext } from "utils/AuthContext";
import { CartContext } from "utils/CartContext";
import { Typography } from "@mui/material";




// Migration const useStyles = makeStyles(styles);

export default function ProductPage(props) {
  let { id } = useParams();

  const authContext = useContext(AuthContext);
  const userUID = authContext.user.uid;
  const cartContext = useContext(CartContext);

  const [productDetails, getProductDetails] = useState({});
  const [price, setPrice] = useState({});

  let productData;
  let priceData;

  if (props.location) {
    productData = props.location.productProps;
    priceData = props.location.priceProps;
  }


  let loaded = false;
  if (productData) {
    //productDetails = productData;
    //productDetails.price = priceData;
    //loaded = true;
    loaded = false;
  }

  useEffect(() => {
    if (loaded == false) {
      getProduct();
    }
  }, []);

  const getProduct = () => {
    let result = devUrl.get("/product/" + id).then((response) => {
      const product = response.data;
      getProductDetails(product);
      let price = parseFloat(product.price_usd.units + "." + parseInt(product.price_usd.nanos / 10000000));
      setPrice(price)
      ReactGA.event( "view_item",{ currency:'USD', value:price,items:[{item_id:product.id,item_name:product.name}]});
    });
  };

  const addToCart = (product) => {
    let productId = product.id;
    console.log("Product Added to cart=>" + productId);
    let result = API.get("/addToCart/" + userUID + "/" + productId).then((response) => {
      const resp = response.data;
      cartContext.refreshCart()
      ReactGA.event( "add_to_cart",{ currency:'USD', value:price,items:[{item_id:product.id,item_name:product.name}]});
    });
  }



  console.log(productDetails);
  if (Object.keys(productDetails).length > 0) {
    return (
      <div>
        <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
          <Grid container alignItems="center" rowSpacing={4}>
            <Grid item xs={12} >
              <Stack spacing={2}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  {productDetails.parent_categories.map((item) => (
                    <Link key={item.id}
                      underline="hover" component={RouterLink}
                      to={{
                        pathname: (item.name == 'Accueil' ? "/" : "/category/" + item.id),
                      }}
                    >
                      <Typography variant="subtitle2">{item.name}</Typography>

                    </Link>

                  ))}
                </Breadcrumbs>
              </Stack>
            </Grid >
            <Grid item xs={12} sm={6} lg={6} align="center" sx={{ marginLeft: "auto", marginRight: "auto" }}>
              <Paper elevation={3}>
                <MKBox sx={{ marginLeft: "auto", marginRight: "auto", marginTop: "20px", marginBottom: "20px" }}
                  component="img"
                  src={productDetails.picture}
                  alt={productDetails.name}
                  borderRadius="lg"
                  width="15rem"
                  position="relative"
                  zIndex={1}
                />
              </Paper>

            </Grid>
            <Grid item xs={12} sm={6} lg={6} align="center" sx={{ marginLeft: "auto", marginRight: "auto" }}>
              <Grid container>
                <Grid item xs={12} lineHeight="4">
                  <MKTypography variant="h1" color="info" textGradient>
                    <CountUp end={price} prefix="€" decimals="2" decimal="." duration={1} />
                  </MKTypography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" startIcon={<AddShoppingCartIcon />} color="info" onClick={() => {
                    addToCart(productDetails)
                  }}>
                    Ajouter au panier
                  </Button>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={12} sm={12} >
              <MKBox component="section">
                <Container>
                  <Grid container alignItems="center">
                    <Grid item xs={12} lg={5}>
                      <MKTypography variant="h3" my={1}>
                        {productDetails.name}
                      </MKTypography>
                      <MKTypography variant="body2" color="text" mb={2}>
                        {productDetails.description}
                      </MKTypography>

                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                      <Stack>
                        <MKBox display="flex" alignItems="center" p={2}>
                          <MKBox
                            width="3rem"
                            height="3rem"
                            variant="gradient"
                            bgColor="info"
                            color="white"
                            coloredShadow="info"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="xl"
                          >
                            <Icon fontSize="small">mediation</Icon>
                          </MKBox>
                          <MKTypography variant="body2" color="text" pl={2}>
                            {productDetails.informations}
                          </MKTypography>
                        </MKBox>
                        <MKBox display="flex" alignItems="center" p={2}>
                          <MKBox
                            width="3rem"
                            height="3rem"
                            variant="gradient"
                            bgColor="info"
                            color="white"
                            coloredShadow="info"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="xl"
                          >
                            <Icon fontSize="small">settings_overscan</Icon>
                          </MKBox>
                          <MKTypography variant="body2" color="text" pl={2}>
                            {productDetails.conservation}
                          </MKTypography>
                        </MKBox>

                      </Stack>

                    </Grid>
                    {productDetails.nutritionelements && productDetails.nutritionelements.length && (
                      <Grid item xs={12} lg={12} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                        <Stack>
                          <MKBox display="flex" alignItems="center" p={2}>
                            <TableContainer >
                              <Table sx={{ minWidth: 300 }} aria-label="Nutrition table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Element</TableCell>
                                    <TableCell align="right">Pour 100g</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {productDetails.nutritionelements.map(
                                    (row) => (
                                      <TableRow key={row.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell component="th" scope="row">
                                          {row.label}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row._100g_value}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}

                                </TableBody>
                              </Table>
                            </TableContainer>
                          </MKBox>

                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Container>
              </MKBox>

            </Grid >


          </Grid >
        </Container>
      </div>
    );
  } else {
    return <div></div>;
  }
}
