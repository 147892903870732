import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";

//Context
import { AuthContext } from "utils/AuthContext.js";

import SignIn from "components/SignIn/SignIn.js";
import SignUp from "components/SignIn/SignUp.js";

//Migration import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/images/bg3.jpg";
import { AuthConsumer } from "utils/AuthContext";


export default function AuthPage(props) {
  const { ...rest } = props;
  const authContext = useContext(AuthContext);
  const auth = authContext.auth;
  const provider = authContext.provider;

  //

  return (
    <div>

        <AuthConsumer>
          {(authContext) => (
            console.log(authContext.isSignUp),
            authContext.isSignUp ? (
              <SignUp auth={auth} provider={provider} />
            ) : (
              <SignIn auth={auth} provider={provider} />
            )
          )}
        </AuthConsumer>
      </div>
  );
}
