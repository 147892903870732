import React from "react";

//Migratino import classNames from "classnames";


// @mui/material components
import { makeStyles } from "@mui/styles";

import Grid from '@mui/material/Grid';

import MKBox from "components/MKBox";

//styles
// Migration import styles from "assets/jss/material-kit-react/views/homepage.js";

import bgImage from "assets/images/header_vegetables.jpg";

export default function HealthPage(props) {

  return (
    <div>
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div >
          <Grid container>
            <Grid item>
              <div >
                <h1>Demo Boutique </h1>
              </div>
            </Grid>
          </Grid>
        </div>

      </MKBox>
      <div></div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <h5> The webfront end is up and running!</h5>
          </Grid>
      </Grid>
    </div>
  );
}
