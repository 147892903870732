import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NavLink, Link as RouterLink } from "react-router-dom";
import ReactGA from "react-ga4";

//Sections
import ProductList from "../Components/Sections/ProductList.js";

// @mui/material components
import { makeStyles } from "@mui/styles";
import { emphasize, styled } from '@mui/material/styles';

// core components
import Container from "@mui/material/Container";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from "@mui/material/Stack";
import FilterListIcon from '@mui/icons-material/FilterList';
import Pagination from "@mui/material/Pagination";


//styles
// Migration// Migration import styles from "assets/jss/material-kit-react/views/homepage.js";
import { devUrl } from "utils/API.js";
import { Grid } from "@mui/material";
import ProductCategory from "pages/Components/Sections/HomeProductCategories.js";


export default function CategoryPage(props) {
  let { id } = useParams();
  const page_size = 20;

  const [page, setPage] = useState(1);
  const [previous_page, setPreviousPage] = useState(0);
  const [total_products, setTotalProductsInCat] = useState(0);
  const [total_pages, setTotalPages] = useState(0);
  const [first_document_id, setFirstDocumentId] = useState();
  const [last_document_id, setLastDocumentId] = useState();
  const [product_list, setProductList] = useState({});
  const [categoryDetails, getCategoryDetails] = useState({});
  useEffect(() => {
    getCategory();
  }, [id]);

  useEffect(() => {
    getProducts();
  }, [page]);

  const getCategory = () => {


    let result = devUrl.get("/category/" + id).then((response) => {
      const category = response.data;
      console.log(category);
      let size = category.current_category.size;
      setTotalProductsInCat(size);
      if ((size % page_size) > 0) {
        setTotalPages(Math.floor(size / page_size) + 1)
      } else {
        setTotalPages(Math.floor(size / page_size))
      }
      if (page != 1) {
        setPage(1);
      } else {
        getProducts();
      }

      getCategoryDetails(category);

    });


  };

  const getProducts = () => {
    let params = 'category_id=' + id;
    params += "&page_size=" + page_size;
    if (page > 1) {
      params += "&page_num=" + page;
      params += "&previous_page_num=" + previous_page;
      params += "&firstdocumentid=" + first_document_id;
      params += "&lastdocumentid=" + last_document_id;
    }
    console.log(params);

    let result = devUrl.get("/products?" + params).then((response) => {
      const products = response.data.products;
      console.log(products);
      setFirstDocumentId(products[0].id);
      setLastDocumentId(products[products.length - 1].id);
      setProductList(products);

      let products_for_tracking = [];
      products.forEach( function (item,index){
        products_for_tracking.push({item_id:item.id,item_name:item.name});
      }) 
      ReactGA.event( "view_item_list",{ item_list_id:id,items:products_for_tracking});
    });


  }

  const handlePageChange = (event, value) => {
    setPreviousPage(page);
    setPage(value);
  };



  return (
    <div>
      <Container maxWidth="lg" style={{ marginTop: "2rem" }}>
        <Grid container rowSpacing={4}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              {categoryDetails.categories &&
                categoryDetails.categories.length > 0 && (
                  <FilterListIcon />
                )}

              {categoryDetails.categories &&
                categoryDetails.categories.length > 0 &&
                categoryDetails.categories.map((item, index) => (
                  <Chip component={RouterLink}
                    to={{
                      pathname: "/category/" + item.id,
                    }}
                    label={item.name}
                    key={item.id}
                    clickable

                  />
                ))}
            </Stack>
          </Grid>

          {product_list && product_list.length > 0 && (
            <Grid item xs={12}>
              <Typography variant="h3">
                Produits
              </Typography>
            </Grid>
          )}
          {product_list && product_list.length > 0 && (
            <Grid item xs={12}>
              <ProductList products={product_list} />
            </Grid>
          )}
          <Grid item xs={12} display="flex" >
            <Pagination count={total_pages} page={page} onChange={handlePageChange} sx={{ "margin": "auto" }} size="large" color="info" />
          </Grid>

        </Grid>
      </Container>
    </div>
  );
}
