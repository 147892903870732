import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

import API from "utils/API";

// core components
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

import Badge from "components/MKBadge";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Grid from '@mui/material/Grid';

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

import Paper from "@mui/material/Paper";

// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @mui/material components
import { makeStyles } from "@mui/styles";

// @mui/icons
import Timeline from "@mui/icons-material/Timeline";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

//specific components
import RecipeCard from "components/RecipeCard/RecipeCard.js";

//styles
// Migration import styles from "assets/jss/material-kit-react/views/components.js";


function valuetext(value) {
  return `${value}°C`;
}

export default function RecipePage(props) {
  let { id } = useParams();

  const [recipeDetails, getRecipeDetails] = useState({});
  const [checked, setChecked] = React.useState([0]);

  let recipeData = props.location.recipeProps;
  let priceData = props.location.priceProps;

  let loaded = false;
  if (recipeData) {
    //recipeDetails.recipe = recipeData;
    //recipeDetails.price = priceData;
    //loaded = true;
    loaded = false;
  }

  useEffect(() => {
    if (loaded == false) {
      getRecipe();
    }
  }, []);

  const getRecipe = () => {
    let result = API.get("/recipe/" + id).then((response) => {
      const recipe = response.data;
      getRecipeDetails(recipe);
    });
  };

  const recipe = recipeDetails.recipe;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  console.log(recipeDetails);
  if (Object.keys(recipeDetails).length > 0) {
    return (
      <div>
        <Container maxWidth="lg" style={{ marginTop: "8rem" }}>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                {recipeDetails.category.parent_categories.map((item) => (
                  <NavLink
                    to={{
                      pathname:
                        item.name == "Accueil" ? "/" : "/category/" + item.id,
                    }}
                  >
                    <Badge color="gray">{item.name}</Badge>
                  </NavLink>
                ))}
              </Breadcrumbs>
            </Grid >
            <Grid item xs={12} sm={8} style={{ marginTop: "2rem" }}>
              <Paper style={{ padding: "5rem" }}>
                <Grid container>
                  <Grid item>
                    <Box
                      display="flex"
                      width="100%"
                      paddingTop="2rem"
                      paddingBottom="2rem"
                    >
                      <Box m="auto">
                        <img src={recipe.picture} style={{ width: "100%" }} />
                      </Box>
                    </Box>
                  </Grid >

                  {recipeDetails.recipe.steps && (
                    <Grid item>
                      <List component="nav" aria-label="">
                        {recipeDetails.recipe.steps.map((step, index) => {
                          return (
                            <ListItem button>
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                              <ListItemText>{step}</ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid >
                  )}
                  {recipeDetails.recipe.preparation && (
                    <Grid item>

                    </Grid >
                  )}
                </Grid >
              </Paper>
            </Grid >

            <Grid item xs={12} sm={4}>
              <Grid >
                <Grid item>
                  <RecipeCard recipe={recipe} hidePicture={true} />
                </Grid >
                <Grid item>
                  <span>
                    <Typography id="discrete-slider" gutterBottom>
                      Nombre de personnes:
                    </Typography>
                    <Slider
                      defaultValue={2}
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="on"
                      step={1}
                      marks
                      min={1}
                      max={10}
                    />
                  </span>
                </Grid >
                <Grid item>
                  <List component="nav" aria-label="">
                    {recipeDetails.recipe.ingredients.map(
                      (ingredient, index) => {
                        return (
                          <ListItem button>
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={false}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            <ListItemText>
                              {(ingredient.quantity
                                ? ingredient.quantity
                                : "") +
                                (ingredient.unit ? " " + ingredient.unit : "") +
                                " " +
                                ingredient.name}
                            </ListItemText>
                          </ListItem>
                        );
                      }
                    )}
                  </List>
                </Grid >
              </Grid >
            </Grid >
          </Grid>
        </Container>
      </div>
    );
  } else {
    return <div></div>;
  }
}
