import React, { useContext, useState } from "react";



//Context
import { AuthConsumer, AuthProvider, AuthContext } from "utils/AuthContext";

import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import  Alert  from "@mui/material/Alert";
import  AlertTitle from "@mui/material/AlertTitle";

import GoogleIcon from "@mui/icons-material/Google";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";


export default function SignIn(props) {
  const authContext = useContext(AuthContext)

  const auth = props.auth;
  const provider = props.provider;



  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [error, setError] = useState(null);
  const [user, setAuthState] = useState("")

  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);


  const signin = () => {

    authContext.signup(false);
    console.log("signup=>" + authContext.isSignUp);
  }

  const signInWithGoogle = () => {
    setError(null)
    auth
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;
      })
      .catch((error) => {
        setError("Error signing in with Google");
        console.error("Error signing in with Google", error);
      });
  };
  const signUpWithEmailAndPasswordHandler = () => {
    setError(null)
    //event.preventDefault();
    console.log(email);
    console.log(lastname);
    auth.createUserWithEmailAndPassword(email, password).catch((error) => {
      setError(error.message);
      console.error("Error signing up with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPassword") {
      setPassword(value);
    }
  };
  return (
    <div>
      <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MKBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  Welcome! - Sign Up
                </MKTypography>
              </MKBox>
              <MKBox pt={4} pb={3} px={3}>
                <MKBox component="form" role="form">
                  <MKBox mb={2}>
                    <MKInput type="text" label="FirstName" fullWidth value={firstname} onChange={(event) => setFirstName(event.target.value)} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="text" label="LastName" fullWidth value={lastname} onChange={(event) => setLastName(event.target.value)} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="email" label="Email" fullWidth value={email} onChange={(event) => setEmail(event.target.value)} />
                  </MKBox>
                  <MKBox mb={2}>
                    <MKInput type="password" label="Password" fullWidth value={password} onChange={(event) => setPassword(event.target.value)} />
                  </MKBox>
                  {error && (
                  <MKBox display="flex" alignItems="center" ml={-1}>
                    <Alert severity="error">
                      <AlertTitle>Error</AlertTitle>
                      {error}
                    </Alert>
                  </MKBox>
                  )}
                  <MKBox mt={4} mb={1}>
                    <MKButton onClick={signUpWithEmailAndPasswordHandler} variant="gradient" color="info" fullWidth>
                      sign up!
                    </MKButton>
                  </MKBox>
                  <MKBox mt={3} mb={1} textAlign="center">
                    <MKTypography variant="button" color="text">
                      Already have an account?{" "}
                      <MKTypography
                        component={Link}
                        to="/auth"
                        onClick={signin}
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Sign in
                      </MKTypography>
                    </MKTypography>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Card>
          </Grid>
        </Grid>
      </MKBox>
    </div>
  )
}