import React, { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga4";

import API from "utils/API";

// core components
import Container from "@mui/material/Container";

import Grid from '@mui/material/Grid';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from "@mui/material/Paper";

import MKButton from "components/MKButton";

// @mui/material components
import { makeStyles } from "@mui/styles";

// @mui/icons
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

//Context
import { AuthContext } from "utils/AuthContext";
import { CartContext } from "utils/CartContext";

//styles
//import styles from "assets/jss/material-kit-react/views/components.js";

import { cardTitle } from "assets/jss/material-kit-react.js";
import { CartProvider } from "utils/CartContext";
import { Typography, Divider } from "@mui/material";

const styles = {
  cardTitle,
  prodcard: {
    textAlign: "center",
    maxWidth: "17rem",
    mminWidth: "15rem",
  },
  prodCardWithPicture: {
    height: "20rem",
  },
  prodFooter: {
    fontWeight: 700,
  },
  cardActions: {
    marginLeft: "auto",
  },
};

const useStyles = makeStyles(styles);

export default function CartPage(props) {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const userUID = authContext.user.uid;

  const cartContext = useContext(CartContext)

  const [newOrder, getNewOrder] = useState(null);

  useEffect(() => {
    cartContext.refreshCart();
  }, []);


  const placeOrder = () => {
    let result = API.get("/placeOrder/" + userUID).then((response) => {
      const newOrder = response.data;
      console.log(newOrder);
      getNewOrder(newOrder);
      cartContext.refreshCart();
      let price = parseFloat(newOrder.totalPrice.units + "." + parseInt(newOrder.totalPrice.nanos / 10000000));
      ReactGA.event( "purchase",{ currency:'USD',transaction_id:newOrder.id, value:price});
    });
  };

  const cart = cartContext.cart;

  if (cart && cart.cartItems && cart.cartItems.length > 0) {
    return (
      <Container maxWidth="lg" sx={{ marginTop: "20px" }}>
        <Grid container columnSpacing={4} rowSpacing={2}>
          <Grid item sm={8}>
            <Typography variant="h3">Votre Panier</Typography>
          </Grid>
          <Grid item sm={8}>
            <Grid container rowSpacing={2}>
              {cart.cartItems.map((item, index) => {
                return (
                  <Grid item sm={12}>
                    <Card >
                      <div >
                        <CardContent>
                          <Grid container alignItems="center">
                            <Grid item sm={3} >
                              <img
                                style={{
                                  width: "6rem",
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                }}
                                className={classes.imgCardTop}
                                src={item.Item.picture}
                                alt="Card-img-cap"
                              />
                            </Grid>
                            <Grid item sm={9}>
                              <Typography variant="h6">
                                {item.Item.name}
                              </Typography>
                              <Typography variant="subtitle2">Quantitée: {item.Quantity}</Typography>
                              <Typography variant="subtitle2">
                                Prix:{" "}
                                {(item.Price.units ? item.Price.units : 0) +
                                  "," +
                                  parseInt(item.Price.nanos / 10000000) +
                                  " " +
                                  item.Price.currency_code}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </div>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <Card style={{ width: "20rem" }}>
              <CardContent>
                <Typography variant="h4">Synthèse</Typography>
                <Divider />
                <Typography variant="h3">
                  Total:{" "}
                  {(cart.totalPrice.units ? cart.totalPrice.units : 0) +
                    "," +
                    parseInt(cart.totalPrice.nanos / 10000000) +
                    " " +
                    cart.totalPrice.currency_code}
                </Typography>
                <Typography variant="h5">
                  Livraison:{" "}
                  {(cart.shippingPrice.units ? cart.shippingPrice.units : 0) +
                    "," +
                    parseInt(cart.shippingPrice.nanos / 10000000) +
                    " " +
                    cart.shippingPrice.currency_code}
                </Typography>
                <Divider />
                <MKButton onClick={placeOrder} color="primary">
                  Commander
                </MKButton>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  } else {
    if (newOrder) {
      console.log(newOrder)
      return (
        <Container maxWidth="lg" style={{ marginTop: "8rem" }}>
          <Paper elevation={3}>
            <MKBox padding={10}>
              <MKTypography variant="h3" my={1}>
                {`Votre commande  ${newOrder.id} a bien été prise en compte.`}
              </MKTypography>
              <MKTypography variant="body2" color="text" mb={2}>
                Nous vous remercions pour votre achat et mettons tout en oeuvre pour vous livrer le plus rapidement possible!
              </MKTypography>
            </MKBox>
          </Paper>
        </Container>
      );
    } else {
      return (
        <div>
          <Container maxWidth="lg" style={{ marginTop: "8rem" }}>
            <Paper elevation={3}>
              <MKBox padding={10}>
                <MKTypography variant="h3" my={1}>
                  Votre panier est vide
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={2}>
                  Vous n'avez pas encore de produits dans votre panier!
                </MKTypography>
              </MKBox>
            </Paper>
          </Container>
        </div>
      );
    }
  }
}
